<template>
  <div>
    <b-card no-body class="text-center mt-1">
      <template #header>
        <b-container fluid class="w-100">
          <b-row>
            <b-col cols="8" class="text-left">
              <h4 class="mb-0">
                Daftar Laporan
              </h4>
            </b-col>
            <b-col cols="4" class="text-right">
              <!-- <b-badge pill variant="success">Terverifikasi</b-badge> -->
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                to="/probono/laporan/tambah"
              >
                Tambah Laporan Baru
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </template>
      <b-card-body class="text-left mt-1 ml-2 mr-2 mb-2">
        <b-row>
          <b-col md="6">
            <b-form-group>
              <label>Nama:</label>
              <b-form-input
                v-model="selectedNama"
                placeholder="Search"
                type="text"
                class="d-inline-block"
                @keyup.enter="onEnterClick"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Klien:</label>
              <b-form-input
                v-model="selectedKlien"
                placeholder="Search"
                type="text"
                class="d-inline-block"
                @keyup.enter="onEnterClick"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2 align-items-end">
          <b-col
            md="8"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="selectedPerPage"
              :options="optionsPerPage"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="filterSelected"
            />
            <label>(Found {{ pagination.totalRows }} records)</label>
          </b-col>
          <b-col md="4">
            <label>Sort by</label>
            <v-select
              v-model="selectedSortBy"
              label="nama"
              :options="optionsSortBy"
              :clearable="false"
              @input="filterSelected"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-table
              responsive
              :items="daftarUser"
              :fields="fields"
              :busy="isBusy"
              class="mt-2"
            >
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle" />
                  <strong> Loading...</strong>
                </div>
              </template>
              <template #cell(id)="data">
                {{ data.value }}
              </template>
              <template #cell(dpc)="data">
                <template v-if="data.item.struktur_organisasi">
                  {{ data.item.struktur_organisasi[0].nama }}
                </template>
              </template>
              <template #cell(klien)="data">
                <li v-for="item in data.item.probono_layanan" :key="item.id">
                  {{ item.klien }}
                </li>
              </template>
              <template #cell(jam)="{item}">
                {{ item.probono_layanan_jumlahjam }} Jam
              </template>
              <template #cell(action)="data">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger"
                  class="btn-icon rounded-circle"
                  size="sm"
                  @click="confirmDelete(data.index)"
                >
                  <feather-icon icon="DeleteIcon" />
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="btn-icon rounded-circle ml-1"
                  size="sm"
                  :to="{
                    name: 'probono-laporan-ubah',
                    params: { id: data.item.id },
                  }"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="btn-icon rounded-circle ml-1"
                  size="sm"
                  :to="{
                    name: 'probono-layanan',
                    params: {
                      id: data.item.id,
                      biodata_id: data.item.biodata_id,
                    },
                  }"
                >
                  <feather-icon icon="ListIcon" />
                </b-button>
                <!-- <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="btn-icon rounded-circle ml-1"
                  size="sm"
                >
                  <feather-icon icon="FileIcon" />
                </b-button> -->
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-pagination-nav
              v-if="showPagination"
              v-model="selectedPageNum"
              :link-gen="linkGen"
              :number-of-pages="pagination.numberOfPages"
              use-router
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  VBModal,
  BButton,
  BRow,
  BCol,
  BCardBody,
  BFormGroup,
  BFormInput,
  BContainer,
  BTable,
  // BLink,
  BPaginationNav,
  BSpinner,
  // BDropdown,
  // BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
// import moment from 'moment'
import { api } from '@/hap-sia/setup'
// import { forEach } from 'postcss-rtl/lib/affected-props'

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BContainer,
    BTable,
    // BLink,
    BPaginationNav,
    BSpinner,
    // BDropdown,
    // BDropdownItem,
    BButton,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      optionsPerPage: [1, 3, 10, 25, 50, 100],
      selectedPerPage: 10,
      optionsSortBy: [
        {
          nama: 'Last Created',
          query: 'created_at|desc',
          id: 1,
        },
        {
          nama: 'Last Updated',
          query: 'updated_at|desc',
          id: 2,
        },
      ],
      selectedSortBy: {
        nama: 'Last Created',
        query: 'created_at|desc',
        id: 1,
      },
      fields: [
        { key: 'id', label: 'ID' },
        { key: 'biodata[0].nama_lengkap', label: 'Nama' },
        { label: 'DPC', key: 'dpc' },
        { label: 'Klien', key: 'klien' },
        { label: 'Total Jam', key: 'jam' },
        {
          key: 'action',
          label: 'Action',
          tdClass: 'custom-cell-action',
        },
      ],
      daftarUser: [
        {
          id: '123',
          nama: 'Himawan Anindya Putra',
          email: 'saya@saya.com',
        },
      ],
      isBusy: false,
      selectedPageNum: 1,
      showPagination: false,
      pagination: {
        numberOfPages: 1,
        totalRows: 0,
        rowsPerPage: 0,
      },
      selectedNama: null,
      selectedKlien: null,
      selectedNia: null,
      selectedKonten: null,
      currentQuery: null,
      currentQueryParam: null,
      biodata_id: null,
      role: null,
      is_rekap: 0,
    }
  },
  async mounted() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    this.role = userData.role

    if (this.role !== 'admin') {
      this.biodata_id = userData.biodata_id
    } else {
      this.biodata_id = this.$route.params.id
    }

    if (this.$route.params.is_rekap) {
      this.is_rekap = 1
    }
    const queryParams = this.processQueryString(this.$route.query)
    this.selectedPerPage = this.$route.query.limit
      ? this.$route.query.limit
      : 10

    // const generatedQueryString = this.buildQueryString(queryParams)
    await this.executeBackend(queryParams)
    this.showPagination = true
  },
  async beforeRouteUpdate(to, from, next) {
    // console.log(to)
    const queryParams = this.processQueryString(to.query)
    // const generatedQueryString = this.buildQueryString(queryParams)
    this.executeBackend(queryParams)
    next()
  },
  methods: {
    linkGen(pageNum) {
      const queryParams = this.processQueryString(this.currentQuery)
      queryParams.page = pageNum
      const generatedQueryString = this.buildQueryString(queryParams)
      // console.log(generatedQueryString)

      return `${generatedQueryString}`
      // console.log(pageNum)
    },
    newQueryParams() {
      return {
        role: null,
        nama: null,
        nia: null,
        konten: null,
        sort_by: null,
        limit: 10,
        offset: null,
        page: 1,
      }
    },
    processQueryString(queryString) {
      const queryParams = this.newQueryParams()

      queryParams.is_rekap = this.is_rekap

      queryParams.biodata_id = this.biodata_id ? this.biodata_id : null

      queryParams.role = this.role ? this.role : null

      const selectedNama = this.selectedNama ? this.selectedNama : null
      queryParams.nama = queryString.nama ? queryString.nama : selectedNama

      const selectedKlien = this.selectedKlien ? this.selectedKlien : null
      queryParams.klien = queryString.klien ? queryString.klien : selectedKlien

      queryParams.sort_by = queryString.sort_by
        ? queryString.sort_by
        : this.selectedSortBy.query
      queryParams.limit = queryString.limit
        ? queryString.limit
        : this.selectedPerPage
      queryParams.offset = queryString.offset ? queryString.offset : 0
      queryParams.page = queryString.page ? queryString.page : 1

      return queryParams
    },
    buildQueryString(params) {
      const queryParams = params

      // disinilah kita merubah offset disesuaikan dengan param page
      const { page } = queryParams
      // hitung offset
      if (page) {
        if (page === '1') {
          queryParams.offset = 0
        } else {
          queryParams.offset = (page - 1) * queryParams.limit
        }
      }
      const keys = Object.keys(queryParams)
      let i = 0
      let currentQuery = ''
      keys.forEach(element => {
        if (
          queryParams[element] !== null &&
          queryParams[element] !== undefined
        ) {
          if (i === 0) {
            currentQuery += '?'
            i += 1
          } else {
            currentQuery += '&'
          }
          currentQuery += `${element}=${queryParams[element]}`
        }
      })
      return currentQuery
    },
    async executeBackend(queryParams) {
      const generatedQueryString = this.buildQueryString(queryParams)
      this.isBusy = true
      // console.log(`executing ${generatedQueryString}`)
      try {
        this.daftarUser.splice(0, this.daftarUser.length)
        const result = await api().get(
          `search/probono/laporan${generatedQueryString}`,
        )
        result.data.items.forEach(element => {
          this.daftarUser.push(element)
        })
        if (result.data.items) {
          const x = result.data.items
          x.forEach((element, index) => {
            let z = 0
            const y = element.probono_layanan
            y.forEach(elementY => {
              z += parseInt(elementY.jumlah_waktu, 10)
            })
            result.data.items[index].probono_layanan_jumlahjam = z
          })
          console.log(result.data)
        }
        this.pagination.totalRows = result.data.total
        this.pagination.numberOfPages = Math.ceil(
          this.pagination.totalRows / queryParams.limit,
        )
        if (this.pagination.numberOfPages === 0) {
          this.pagination.numberOfPages = 1
        }
        this.selectedNama = queryParams.nama
        this.selectedKlien = queryParams.klien
        this.selectedNia = queryParams.nia
        this.selectedPageNum = queryParams.page
        this.selectedSortBy = this.optionsSortBy.find(
          element => element.query === queryParams.sort_by,
        )
      } catch (error) {
        alert(error)
      }
      this.isBusy = false
      this.currentQuery = generatedQueryString
      this.currentQueryParam = queryParams
    },
    async filterSelected() {
      // reset query
      const queryParams = this.processQueryString({})
      const generatedQueryString = this.buildQueryString(queryParams)
      this.$router.push({
        path: `${generatedQueryString}`,
      })
    },
    makeToast(variant = null, title, body, noAutoHide = false) {
      this.$bvToast.toast(body, {
        title,
        variant,
        solid: true,
        autoHideDelay: 3000,
        noAutoHide,
      })
    },
    onEnterClick() {
      this.filterSelected()
    },
    tambahPenggunaBaru() {
      this.$router.push({
        name: 'support-add',
      })
    },
    confirmDelete(index) {
      this.$bvModal
        .msgBoxConfirm('Apakah anda yakin?', {
          cancelVariant: 'outline-secondary',
          okTitle: 'Hapus',
          cancelTitle: 'Batal',
          okVariant: 'danger',
        })
        .then(async value => {
          if (value) {
            try {
              this.isBusy = true
              const deleteLaporan = this.daftarUser[index]
              await api().delete(`probono/laporan/${deleteLaporan.id}`)
              this.daftarUser.splice(index, 1)
            } catch (error) {
              this.makeToast(
                'danger',
                'Error',
                error.response.data.message,
                true,
              )
              this.isBusy = false
              return
            }
            this.makeToast('success', 'Sukses', 'Data berhasil dihapus')
            this.isBusy = false
          }
        })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style>
.custom-cell-date {
  min-width: 200px;
  /* color: green;
  background-color: #00ff88; */
}
.custom-cell-action {
  min-width: 140px;
  /* color: green;
  background-color: #00ff88; */
}
</style>
